/**
 * iap
 * Internetovy aukcni portal
 *
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */

import { Item } from './item';
import { MyUser } from './myUser';
import { Auction } from './auction';


export interface WinnerAdmin { 
    user: MyUser;
    items?: Array<Item>;
    auction: Auction;
}

