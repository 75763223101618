/**
 * iap
 * Internetovy aukcni portal
 *
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */

import { ItemPicUrl } from './itemPicUrl';
import { Author } from './author';


/**
 * (tsType: ItemAdmin, schemaOptions: { includeRelations: false })
 */
export interface ItemAdmin { 
    id?: number;
    itemName: string;
    itemNameEN: string;
    description?: string | null;
    descriptionEN?: string | null;
    author?: string | null;
    size?: string;
    priceEstimationLow?: number;
    priceEstimationHigh?: number;
    priceCurrent?: number;
    priceFinal?: number;
    startPriceForBidding?: number;
    startPriceForClosing?: number;
    idWinningUser?: string;
    idRepresentativeBidder?: string;
    itemOrder?: number;
    withoutFee?: boolean;
    idAuction?: number;
    isHighlight?: number | null;
    dating?: string | null;
    datingEN?: string | null;
    signatureCS?: string | null;
    signatureEN?: string | null;
    propertiesCS?: string | null;
    propertiesEN?: string | null;
    winningEmailSent?: number;
    technique?: string | null;
    techniqueEN?: string | null;
    last_modified?: string;
    canvasId?: number | null;
    likesCount?: number;
    originalCatalogNumber?: number | null;
    preClosingAvailable?: boolean | null;
    idType?: number;
    idStatus?: number;
    type?: string;
    status?: string;
    hasBid?: boolean;
    winnerFirstName?: string;
    winnerLastName?: string;
    signature?: string;
    authors?: Array<Author>;
    pics?: Array<ItemPicUrl>;
}

