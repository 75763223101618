/**
 * iap
 * Internetovy aukcni portal
 *
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */

import { NotificationMessageWithRelations } from './notificationMessageWithRelations';


export interface AdminNotificationsLog { 
    total?: number;
    data?: Array<NotificationMessageWithRelations>;
}

